@import '../../assets/styles/variables';

.history {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  max-width: 700px;
  padding-top: 140px;

  @media screen and (orientation: landscape) {
    padding-top: 70px;
  }

  @media screen and (orientation: portrait) {
    padding-top: 96px;
  }

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    max-width: 526px;
    padding-top: 156px;
  }

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    max-width: 920px;
    padding-top: 171px;
  }

  .container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #010101;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--game-color);
    }
  }

  &-item {
    display: flex;
    background-color: rgba(#000, 0.5);
    padding: 14px 5%;

    &:nth-child(even) {
      background-color: transparent;
    }

    &__col {
      color: #fff;
      font-size: 16px;
      line-height: 20px;
      padding: 0 2%;
      font-weight: 900;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 26px;
        line-height: 30px;
      }

      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 26px;
        line-height: 30px;
      }

      .value {
        color: var(--game-color);
      }

      .freeBet {
        color: var(--game-color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.date {
        align-items: flex-start;
        width: 20%;
        padding-left: 0;
      }

      &.bet {
        align-items: center;
        width: 20%;
      }

      &.win {
        align-items: flex-end;
        width: 20%;
        padding-left: 0;
      }

      &.gameId {
        width: 40%;
        align-items: flex-start;
        padding-right: 0;

        .value {
          -webkit-user-select: all;
          user-select: all;
          cursor: text;

          &::-moz-selection {
            color: #fff;
            background: var(--game-color);
          }

          &::selection {
            color: #fff;
            background: var(--game-color);
          }
        }
      }

      .btn {
        background: none;

        @media (pointer: fine) {
          &:hover {
            scale: 1.11;
          }
        }

        &:active {
          scale: 0.89;
        }

        svg {
          display: block;
          width: 100%;
        }

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    @media only screen and (orientation: portrait) {
      flex-wrap: wrap;

      &__col {
        padding: 1% 0;

        &.date {
          width: 33.333%;
        }

        &.bet {
          width: 33.333%;
        }

        &.win {
          width: 33.333%;
        }

        &.gameId {
          width: 100%;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 1.25vw 0;

  @media only screen and (orientation: portrait) {
    justify-content: flex-end;
    padding-right: 1.25vw;
  }

  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    justify-content: center;
  }

  &.invisible {
    display: none;
  }

  &__btn {
    display: inline-flex;
    width: 7.5vw;
    height: 7.5vw;
    margin: 0 0.6vw;
    background: none;
    transition: opacity 0.3s ease;

    @media only screen and (orientation: portrait) {
      width: 7.5vh;
      height: 7.5vh;
    }

    @media (pointer: fine) {
      &:hover:not(:disabled) {
        scale: 1.11;
      }
    }

    &:active:not(:disabled) {
      scale: 0.89;
    }

    svg {
      display: block;
      width: 100%;
    }

    &:disabled {
      background: none;
      opacity: 0.5;
      cursor: default;
    }
  }

  .active {
    &:active:not(:disabled) {
      scale: 0.89;
    }

    &:focus:not(:disabled) {
      scale: 0.89;
    }
  }


}

.spinner {
  position: relative;
  margin: auto;

  &:after {
    content: ' ';
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    justify-content: center;
    border: 6px solid var(--game-color);
    border-color: var(--game-color) transparent var(--game-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}