.canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  canvas {
    width: 100%;
    height: 100%;
  }
}
